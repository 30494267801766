//------------------------------------------------------------------------------------------------
// SWITCH
//------------------------------------------------------------------------------------------------

.c-switch__input {
	width: rem(60px);
	height: rem(30px);
	background-color: color('light-grey');
	border: rem(1px) solid color('grey');
	border-radius: rem(15px);
	transition: box-shadow $global-transition-duration, background-color $global-transition-duration;
	position: relative;
	cursor: pointer;
	&:before {
		content: "";
		width: rem(30px);
		height: rem(30px);
		border-radius: 50%;
		background-color: #ffffff;
		border: rem(1px) solid color('grey');
		display: block;
		position: absolute;
		top: rem(-1px);
		left: rem(-1px);
		transition: left $global-transition-duration;
	}
	&:focus {
		outline: none;
		box-shadow: 0 rem(1px) rem(3px) rgba(0,0,0, 0.25), 0 0 rem(15px) rem(3px) rgba($btn-color, 0.5) /* [3] */
	}
}

.c-switch__checkbox {
	display: none;
}

.c-switch.is-active .c-switch__input {
	background-color: color('positive');
	&:before {
		left: rem(29px);
	}
}

.c-switch__label {
	display: block;
	cursor: pointer;
	white-space: nowrap;
	font-weight: bold;
	margin-bottom: rem(10px);
}

.c-switch__wrapper {
	display: inline-flex;
	align-items: center;
}

.c-switch__tooltip {
	margin-left: rem(10px);
}

// STATE MODIFIERS
//------------------------------------------------------------------------------------------------

.c-switch.is-disabled,
.is-disabled .c-switch,
*[disabled] .c-switch {
	pointer-events: none;
	.c-switch__input {
		background-color: color('grey');
		&:before {
			background-color: color('light-grey');
		}
	}
}