//------------------------------------------------------------------------------------------------
// BUTTON
//------------------------------------------------------------------------------------------------

/*
Extending toolkit button component to add project specific styles
*/

.c-button--secondary:not(:hover) {
	.c-button__text {
		color: #000000;
	}
}