//------------------------------------------------------------------------------------------------
// HAMBURGER BUTTON
//------------------------------------------------------------------------------------------------

/*
Hamburger-button component to toggle the menu
*/

.c-hamburger-button {
	z-index: 5;
}

.c-hamburger-button:hover {
	.c-hamburger-button__text {
		color: #ffffff;
	}
}

.c-hamburger-button__lines {
	margin-left:5px;
	position:relative;
}

.c-hamburger-button__line {
	display:block;
	width:17px;
	height: 3px;
	background-color: currentColor;
	border-radius:4px;
}

.c-hamburger-button__line--1,
.c-hamburger-button__line--3 {
	position:absolute;
	transition:transform 0.2s, top 0.2s;
	transition-delay:0.1s;
}

.c-hamburger-button__line--1 {
	top:-6px;
}

.c-hamburger-button__line--3 {
	top:6px;
}

.c-hamburger-button__text {
	//flex:1;
	text-align:left;
	user-select:none;
	font-weight:bold;
	color: #000000;
	margin-right: 5px;
}

.c-hamburger-button.is-active {
	border-color:#ffffff;

	.c-hamburger-button__text {
		color:#ffffff;
	}

	.c-hamburger-button__line--2 {
		background-color:transparent;
		opacity:0;
	}

	.c-hamburger-button__line--1,
	.c-hamburger-button__line--3 {
		background-color:#ffffff;
		top:0;
	}

	.c-hamburger-button__line--1 {
		transform:rotate(45deg);
	}

	.c-hamburger-button__line--3 {
		transform:rotate(-45deg);
	}
}
