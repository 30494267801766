//------------------------------------------------------------------------------------------------
// TYPE
//------------------------------------------------------------------------------------------------

/*
Extending toolkit typography component to add project specific styles
*/

.c-type-alpha,
.c-type-bravo,
.c-type-charlie,
.c-type-delta,
.c-type-echo {
	font-family: 'moderat', sans-serif;
}

[class*='c-type'].is-success {
	color: color('positive');
}

[class*='c-type'].is-error {
	color: color('negative');
}

.c-type--invert {
	color: #ffffff;
}

.c-type-body {
	font-size: 1rem;
}

.c-type-link {
	cursor: pointer;
	white-space: nowrap;
}

.c-type-underline {
	text-decoration: underline;
}

.c-type-body-font {
	font-family: 'roboto', sans-serif;
}