//------------------------------------------------------------------------------------------------
// MENU CARD
//------------------------------------------------------------------------------------------------

.c-menu-card {
	width: 100%;
	position: relative;
}

.c-menu-card__icon {
	fill: color('dark-blue');
	display: block;
	margin: 0 auto;
	margin-bottom: rem(20px);
	width: rem(100px);
	height: rem(100px);
	cursor: pointer;
}

.c-menu-card__title {
	display: block;
	text-align: center;
	color: color('green');
	margin-bottom: rem(10px);
	cursor: pointer;
}

.c-menu-card--hollow {
	border: rem(2px) dotted color('grey');
	border-radius: rem(10px);
}

.c-menu-card__minus {
	width: rem(30px);
	height: rem(30px);
	position: absolute;
	top: rem(10px);
	right: rem(10px);
	fill: color('green');
	cursor: pointer;
}

.c-menu-card.is-disabled {
	pointer-events: none;
	opacity: 0.25;
}

.c-menu-card__icon--video,
.c-menu-card__icon--presentation
 {
	width: rem(125px);
}

.c-menu-card__badge {
	position: absolute;
	top: rem(10px);
	right: rem(10px);
}