//------------------------------------------------------------------------------------------------
// LOADING ICON
//------------------------------------------------------------------------------------------------

.c-loading {
	width: rem(75px);
	height: rem(75px);
	display: inline-block;
}

.c-loading__inner {
	width: inherit;
	height: inherit;
	position: relative;
}

.c-loading__circle {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.c-loading .c-loading__circle:before {
	content: '';
	display: block;
	margin: 0 auto;
	width: 15%;
	height: 15%;
	background-color: color('green');
	border-radius: 100%;
	animation: c-loading__circleFadeDelay 1.2s infinite ease-in-out both;
}

.c-loading.c-loading--white .c-loading__circle:before {
	background-color: #ffffff;
}

.c-loading .c-loading__circle--2 {
	transform: rotate(30deg);
}
.c-loading .c-loading__circle--3 {
	transform: rotate(60deg);
}
.c-loading .c-loading__circle--4 {
	transform: rotate(90deg);
}
.c-loading .c-loading__circle--5 {
	transform: rotate(120deg);
}
.c-loading .c-loading__circle--6 {
	transform: rotate(150deg);
}
.c-loading .c-loading__circle--7 {
	transform: rotate(180deg);
}
.c-loading .c-loading__circle--8 {
	transform: rotate(210deg);
}
.c-loading .c-loading__circle--9 {
	transform: rotate(240deg);
}
.c-loading .c-loading__circle--10 {
	transform: rotate(270deg);
}
.c-loading .c-loading__circle--11 {
	transform: rotate(300deg);
}
.c-loading .c-loading__circle--12 {
	transform: rotate(330deg);
}
.c-loading .c-loading__circle--2:before {
	animation-delay: -1.1s;
}
.c-loading .c-loading__circle--3:before {
	animation-delay: -1s;
}
.c-loading .c-loading__circle--4:before {
	animation-delay: -0.9s;
}
.c-loading .c-loading__circle--5:before {
	animation-delay: -0.8s;
}
.c-loading .c-loading__circle--6:before {
	animation-delay: -0.7s;
}
.c-loading .c-loading__circle--7:before {
	animation-delay: -0.6s;
}
.c-loading .c-loading__circle--8:before {
	animation-delay: -0.5s;
}
.c-loading .c-loading__circle--9:before {
	animation-delay: -0.4s;
}
.c-loading .c-loading__circle--10:before {
	animation-delay: -0.3s;
}
.c-loading .c-loading__circle--11:before {
	animation-delay: -0.2s;
}
.c-loading .c-loading__circle--12:before {
	animation-delay: -0.1s;
}

@-webkit-keyframes c-loading__circleFadeDelay {
	0%, 39%, 100% { opacity: 0; }
	40% { opacity: 1; }
}

@keyframes c-loading__circleFadeDelay {
	0%, 39%, 100% { opacity: 0; }
	40% { opacity: 1; }
}