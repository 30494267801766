//------------------------------------------------------------------------------------------------
// CALENDAR
//------------------------------------------------------------------------------------------------

/*
Extending toolkit calendar component to add project specific styles
*/

.c-calendar__buttons {
	margin-bottom: 0;
}

@include mq($until: sm2) {
	.c-calendar__label {
		font-size: 1rem;
	}
	.c-calendar__main th {
		font-size: rem(14px);
	}
}