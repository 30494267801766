//------------------------------------------------------------------------------------------------
// BADGE COMPONENT
//------------------------------------------------------------------------------------------------

/*
A small component used for sublimentary labelling. Similar to tooltip, but with
very short content which is always visible. Think "New", "2 Posts" etc.
*/

.c-badge {
	display: inline-block;
	padding: 0.3em 0.4em 0.25em 0.4em;
	font-size: 70%;
	font-weight: bold;
	text-align: center;
	white-space: nowrap;
	border-radius: $global-border-radius;
	transition: box-shadow $global-transition-duration, background-color $global-transition-duration, border-color $global-transition-duration, color $global-transition-duration;
	background-color: #ff595d;
	color: #ffffff;
	line-height: 1;
	text-transform: uppercase;
	position: relative;
	top: rem(-2px);
	margin-left: 0.3em;
}

.c-badge--round {
	border-radius: 50%;
	padding: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: rem(35px);
	height: rem(35px);
	font-size: rem(20px);
}