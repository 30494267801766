//------------------------------------------------------------------------------------------------
// SUBHEADER
//------------------------------------------------------------------------------------------------

.c-subheader {
	background-color: color('dark-green');
}

.c-subheader__back {
	width: rem(30px);
	height: rem(30px);
}

@include mq($until: sm2) {
	.c-subheader--padding {
		padding-bottom: rem(1px);
	}
}