//------------------------------------------------------------------------------------------------
// TABLE
//------------------------------------------------------------------------------------------------

/*
Extending toolkit table component to add project specific styles
*/

.c-table--top {
	th, td {
		vertical-align: top;
	}
}

[class*='c-table--collapse'] .c-table__mobile--full:before {
	width: 100%;
	margin-bottom: rem(10px);
}