//------------------------------------------------------------------------------------------------
//  FONTS
//------------------------------------------------------------------------------------------------

@font-face {
    font-family: 'moderat';
    src: url('../fonts/moderat-bold-webfont.woff2') format('woff2'),
         url('../fonts/moderat-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'moderat';
    src: url('../fonts/moderat-regular-webfont.woff2') format('woff2'),
         url('../fonts/moderat-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bold-webfont.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'roboto';
    src: url('../fonts/roboto-light-webfont.woff2') format('woff2'),
         url('../fonts/roboto-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}