//------------------------------------------------------------------------------------------------
// MENU
//------------------------------------------------------------------------------------------------

/*
Styles for Menu component
*/

.c-menu {
	background-color: rgba(0,0,0,0);
	transition: background-color 0.25s;
	overflow: hidden;
	z-index: 1;
}

.c-menu__box {
	background-color: color('dark-green');
	color: #ffffff;
	box-shadow: 0 0 rem(40px) rem(-11px) rgba(0,0,0,0);
	right: -100%;
	transition: box-shadow 0.25s, right 0.25s;
	overflow: scroll;
	overflow-x: hidden;
	height: 100vh;
	padding: rem(77px) 0 rem(100px) 0;
}

.c-menu__top {
	height: rem(78px);
	background-color: #046E75;
	position: fixed;
	width: 100%;
	top: 0;
}

.c-menu__item {
	color: inherit;
	text-decoration: none;
	cursor: pointer;
	&:hover,
	&:focus,
	&.is-active {
		color: color('yellow');
		svg {
			fill: color('yellow');
		}
	}
	&.is-disabled {
		opacity: 0.25;
		pointer-events: none;
	}
}

@include mq(sm3) {
	.c-menu__box {
		width: rem(475px);
		right: rem(-475px);
	}
}

.c-menu.is-active {
	background-color: rgba(0,0,0,0.85);
	.c-menu__box {
		right: 0;
		box-shadow: 0 0 rem(40px) rem(-11px) rgba(0,0,0,1);
	}
}

.c-menu.is-hidden {
	width: 0%;
	visibility: hidden;
}

.c-menu__spacer {
	width: 100%;
	height: rem(20px);
	background-color: rgba(0,0,0,0.10);
}

.c-menu__shortcut-control {
	width: rem(14px);
	height: rem(14px);
	position: relative;
	fill: #ffffff;
	top: rem(-3px);
}

.c-menu__shortcut-control--minus {
	left: rem(8px);
	fill: color('yellow');
	cursor: pointer;
}

.c-menu__shortcut-control--plus {
	margin-right: rem(8px);
}

.c-menu--large-top {
	.c-menu__box {
		padding-top: rem(114px)
	}
	.c-menu__top {
		height: rem(114px);
	}
}

.c-menu__app-icon {
	width: rem(30px);
	height: rem(30px);
}

@include mq(lg) {
	.c-menu__box {
		padding-bottom: rem(30px);
	}
}