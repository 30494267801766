//------------------------------------------------------------------------------------------------
// INPUT
//------------------------------------------------------------------------------------------------

/*
Extending toolkit input component to add project specific styles
*/

.c-input textarea {
	line-height: 1.5;
}

// Fix bug with white text for inverted input
.c-input--invert .c-input__field {
	color: #000000;
	padding: rem(15px);
}

// Fix bug with prefix/suffix borders not going red to match rest of errored input
.c-input.is-error {
	.c-input__prefix,
	.c-input__suffix {
		border-color: color('negative');
	}
}

.c-input--standard-text-color {
	.c-input__field {
		color: #000000 !important; // !important required to override any stateful classes such as is-disabled which have higher specificity
	}
}

.c-input__length {
	width: 100%;
	margin-top: rem(10px);
	background-color: color('light-grey');
	border: rem(1px) solid color('grey');
	border-radius: $global-border-radius;
	padding: spacing('tiny');
	text-align: center;
	position: relative;
	overflow: hidden;
}

.c-input__length-bar {
	position: absolute;
	width: 0%;
	height: 100%;
	background-color: color('light-green');
	top: 0;
	left: 0;
	//transition: width 0.25s;
}

.c-input__length-text {
	position: relative;
	font-size: rem(14px);
}

.c-input--calendar {
	.c-input__wrapper {
		cursor: inherit;
	}
	.c-input__suffix {
		cursor: pointer;
	}
	.c-input__field {
		pointer-events: all;
	}
}