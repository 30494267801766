//------------------------------------------------------------------------------------------------
// ALPHABET
//------------------------------------------------------------------------------------------------

.c-alphabet {
	display: flex;
	align-items: center;
	margin: 0 auto;
}

.c-alphabet__letter {
	font-size: rem(20px);
	font-weight: bold;
	min-width: rem(55px);
	max-width: rem(55px);
	height: rem(55px);
	display: flex;
	align-items: center;
	justify-content: center;
	background: none;
	border: none;
	border-radius: rem(5px);
	&:not(.is-disabled) {
		cursor: pointer;
		&:hover {
			background-color: color('grey');
		}
	}
	&.is-active {
		background-color: color('yellow');
		&:hover {
			background-color: darken(color('yellow'), 5%);
		}
	}
	&.is-disabled {
		color: color('grey');
		pointer-events: none;
	}
}

.c-alphabet__container {
	max-width: 100%; /* Fixes IE bug where Alphabet isn't centered and goes off page */
}

@include mq($until: md) {
	.c-alphabet {
		&:before,
		&:after {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: rem(7.5px) rem(10px) rem(7.5px) 0;
			border-color: transparent #000000 transparent transparent;
			margin-right: rem(20px);
		}
		&:after {
			border-width: rem(7.5px) 0 rem(7.5px) rem(10px);
			border-color: transparent transparent transparent #000000;
			margin-left: rem(20px);
			margin-right: 0;
		}
	}

	.c-alphabet__container {
		flex-wrap: nowrap;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
	}
}

@include mq(md) {
	.c-alphabet__container {
		justify-content: center;
	}

	.c-alphabet__letter {
		font-size: rem(24px);
		min-width: rem(65px);
		max-width: rem(65px);
		height: rem(65px);
		margin: rem(5px);
	}
}