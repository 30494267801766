//------------------------------------------------------------------------------------------------
// HEADER
//------------------------------------------------------------------------------------------------

/*
Header component which sits atop every view.
*/

.c-header {
	padding-top: rem(13px);
	padding-bottom: rem(15px);
	position: relative;
}

.c-header__logo {
	width: rem(150px);
	height: rem(37px);
	position: relative;
	top: rem(-2px);
}

.c-header__title {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}