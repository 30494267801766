//------------------------------------------------------------------------------------------------
// MODAL
//------------------------------------------------------------------------------------------------

/*
Extending toolkit modal component to add project specific styles
*/

.c-modal__box {
	-webkit-overflow-scrolling: touch;	
}
