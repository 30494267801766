//------------------------------------------------------------------------------------------------
// FORM
//------------------------------------------------------------------------------------------------

.c-form__icon {
	width: rem(60px);
	height: rem(60px);
}

@include mq(md) {
	.c-form__icon {
		width: rem(100px);
		height: rem(100px);
	}
}