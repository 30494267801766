//------------------------------------------------------------------------------------------------
// ICON
//------------------------------------------------------------------------------------------------

.c-icon svg {
	width: 100%;
	height: 100%;
	pointer-events: none; /* Fixes edge bug with click events not working on SVG */
}

.c-icon--circle-back,
.c-icon--loading {
	width: rem(30px);
	height: rem(30px);
}

.c-icon.is-success {
	fill: color('positive');
}

.c-icon.is-error {
	fill: color('negative');
}

.c-icon--white {
	fill: #ffffff;
}

.c-icon--file-pdf {
	width: rem(50px);
	height: rem(50px);
}

.c-icon--file-ppt {
	width: rem(56px);
	height: rem(50px);
}
.c-icon--video {
	width: rem(56px);
	height: rem(50px);
}