//------------------------------------------------------------------------------------------------
// MENU CARDS
//------------------------------------------------------------------------------------------------

.c-menu-cards__tiles {
	display: grid;
	grid-template-columns: auto;
	grid-auto-rows: auto;
	grid-gap: rem(30px);
}

.c-menu-cards__tile {
	display: flex;
	align-items: stretch;
}

@include mq(sm2) {
	.c-menu-cards__tiles {
		grid-template-columns: repeat(auto-fill, minmax(rem(250px), 1fr));
	}
}