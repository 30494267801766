//------------------------------------------------------------------------------------------------
// BOX
//------------------------------------------------------------------------------------------------

/*
Extending toolkit box component to add project specific styles
*/

.c-box--bordered {
	border-bottom: rem(1px) solid color('grey');
}

.c-box--bordered-vertical {
	border-top: rem(1px) solid color('grey');
	border-bottom: rem(1px) solid color('grey');
}

.c-box--radius {
	border-radius: $global-border-radius;
}

.c-box--no-overflow {
	overflow: hidden;
}

.c-box.is-positive {
	background-color: color('positive');
	color: #ffffff;
}

.c-box.is-error {
	background-color: color('negative');
	color: #ffffff;
}

.c-box--info {
	background-color: #4B8FFF;
	color: #ffffff;
}

.c-box--animated-background {
	transition: background-color $global-transition-duration;
}