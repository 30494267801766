//------------------------------------------------------------------------------------------------
// PRESENTATION ROW
//------------------------------------------------------------------------------------------------

.c-presentation-row {
	border-bottom: rem(1px) solid color('green');
}

.c-presentation-row:first-child {
	border-top: rem(1px) solid color('green');
}
