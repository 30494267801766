//------------------------------------------------------------------------------------------------
// VIDEO
//------------------------------------------------------------------------------------------------

.c-video {
	position: relative;
	border-radius: $global-border-radius;
	overflow: hidden;
	background-color: color('dark-grey');
	&:after {
		content: '';
		background-color: rgba(0,0,0,0.5);
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	img {
		width: 100%;
		height: auto;
	}

	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.c-video__play {
	width: rem(60px);
	height: rem(60px);
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 1;
}

.c-video.is-active {
	&:after,
	.c-video__play {
		display: none;
	}
	img {
		opacity: 0;
	}
}