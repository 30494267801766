//------------------------------------------------------------------------------------------------
// HTML STYLES
//------------------------------------------------------------------------------------------------

/*
Set basic styles for HTML top level element.
*/

/*
[1] Set the default `font-size` and `line-height` for the entire project, sourced
from our default variables. The `font-size` is calculated to exist in ems so
the users browser font-size is respected if changed.
*/

html {
	font-family: 'roboto', sans-serif;
	font-size: ($global-font-size / 16px) * 1em; /* [1] */
	line-height: $global-line-height; /* [1] */
	min-height: 100%;
	overflow-y: scroll;
	 -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
	background-color: color('light-grey');
}

/* Makes user unable to scroll if applied to body */
.is-fixed {
	position: fixed;
	overflow:hidden;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	/* Makes scrolling perform much better with inputs in modals */
	-webkit-overflow-scrolling : touch
}
