//------------------------------------------------------------------------------------------------
// EXPANDER
//------------------------------------------------------------------------------------------------

.c-expander__title {
	cursor: pointer;
	position: relative;
	&:hover,
	&:focus {
		background-color: color('light-grey');
		outline: none;
	}
	&:after {
		display: block;
		content: '';
		position: absolute;
		width: 100%;
		height: rem(1px);
		bottom: rem(-1px);
		left: 0;
		border-bottom: rem(1px) solid color('grey');
	}
}

.c-expander__arrow {
	width: rem(25px);
	height: rem(16px);
}

.c-expander__content {
	max-height: 0;
	transition: max-height 0.25s;
	overflow: hidden;
}

.c-expander.is-active {
	.c-expander__content {
		max-height: rem(1000px);
		overflow: auto;
	}
	.c-expander__arrow {
		transform: rotate(180deg);
	}
}

.c-expander.is-disabled {
	.c-expander__title {
		cursor: initial;
	}
}