//------------------------------------------------------------------------------------------------
// COLOURS
//------------------------------------------------------------------------------------------------

.u-theme-primary {
    background-color: color('dark-green');
}

.u-text-white {
    color: white;
}