//------------------------------------------------------------------------------------------------
// MEDIA COLLECTION
//------------------------------------------------------------------------------------------------

.c-media-collection:not(:first-child) .c-media-collection__title {
	border-top: rem(1px) solid color('green');
	padding-top: rem(30px);
}

@include mq(md) {
	.c-media-collection:not(:first-child) .c-media-collection__title {
		padding-top: rem(50px);
	}
}

.c-media-collection--disable-border:not(:first-child) .c-media-collection__title {
	border-top-width: 0;
}