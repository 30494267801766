//------------------------------------------------------------------------------------------------
// DEALER LIST ITEM
//------------------------------------------------------------------------------------------------

.c-dealer-list-item {
	display: block;
	color: #000000;
	position: relative;
	padding-right: rem(80px);
	&:not(:last-child) {
		border-bottom: rem(1px) solid color('grey');
	}
	&:hover,
	&:focus {
		background-color: color('light-grey');
	}
}

.c-dealer-list-item__title {
	font-size: rem(20px);
	font-weight: 300;
	color: #000000;
}

.c-dealer-list-item__risk {
	border-radius: rem(5px);
	background-color: color('positive');
	position: absolute;
	height: calc(100% - 16px);
	font-size: rem(28px);
	font-weight: bold;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	//padding: rem(12px);
	min-width: rem(60px);
	min-height: rem(50px);
	top: rem(8px);
	right: rem(8px);
	color: #ffffff;
}

.c-dealer-list-item__risk-subtext {
	font-size: rem(14px);
	display: block;
}

.c-dealer-list-item--risk-ungraded {
	.c-dealer-list-item__risk {
		background-color: color('grey');
	}
}

.c-dealer-list-item--risk-bad {
	.dealer-list-item__risk {
		background-color: color('negative');
	}
}

.c-dealer-list-item--risk-worst {
	.c-dealer-list-item__risk {
		background-color: #000000;
	}
}

@include mq(md) {
	.c-dealer-list-item__title {
		font-size: rem(24px);
	}

	.c-dealer-list-item__risk {
		line-height: 1.2;
	}
}