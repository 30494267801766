//------------------------------------------------------------------------------------------------
// HEIGHTS
//------------------------------------------------------------------------------------------------

.u-full-height {
	height: 100vh;
}

// Accounts for header
.u-full-height-header {
	height: calc(100vh - 79px);
}