//------------------------------------------------------------------------------------------------
// INFO ROW
//------------------------------------------------------------------------------------------------

.c-info-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: rem(68px);
	padding: spacing('small');
	flex-wrap: wrap;
	&:not(:last-child) {
		border-bottom: rem(1px) solid color('grey');
	}
}

.c-info-row__wrapper {
	display: flex;
	flex-grow: 1;
	align-items: center;
}

.c-info-row__label {
	font-weight: bold;
	width: 100%;
	margin-bottom: rem(5px);
	display: flex;
}

.c-info-row__controls {
	width: rem(75px);
	display: flex;
	justify-content: flex-end;
	margin-left: rem(5px);
	flex-shrink: 0;
}

.c-info-row__input {
	flex-grow: 1;
	input,
	select {
		transition: background-color 0s, border-color 0s;
	}
	&.is-disabled .c-input__field {
		background-color: transparent;
		border-color: transparent;
		color: inherit;
	}
}

.c-info-row__control {
	width: rem(30px);
	height: rem(30px);
	fill: color('positive');
	cursor: pointer;
	&.is-disabled {
		fill: color('grey');
		pointer-events: none;
	}
	&:hover,
	&:focus {
		fill: darken(color('positive'), 10%);
	}
}

.c-info-row__control--cancel {
	fill: color('dark-green');
	&:hover,
	&:focus {
		fill: darken(color('dark-green'), 15%);
	}
}

.c-info-row__edit-icon {
	width: rem(30px);
	height: rem(30px);
	fill: color('green');
	cursor: pointer;
	&:hover,
	&:focus {
		fill: darken(color('green'), 10%);
	}
}

.c-info-row__switch,
.c-info-row__button {
	margin-left: rem(15px);
}

.c-info-row--read-only {
	.c-info-row__controls {
		display: none;
	}
}

.c-info-row--static-mode {
	.c-info-row__controls {
		width: rem(30px);
	}
}

@include mq(sm3) {
	.c-info-row {
		flex-wrap: nowrap;
		padding: 0 spacing('small');
	}

	.c-info-row__label {
		width: rem(200px);
		margin-bottom: 0;
	}

	.c-info-row__input {
		padding: rem(10px) 0;
	}
}