//------------------------------------------------------------------------------------------------
// TOOLTIP
//------------------------------------------------------------------------------------------------

/*
Extending toolkit tooltip component to add project specific styles
*/

.c-tooltip {
	font-weight: normal;
	color: #000000;
}

.c-tooltip--invert {
	.c-tooltip__icon svg {
		fill: #ffffff;
	}
}