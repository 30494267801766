//------------------------------------------------------------------------------------------------
// TABS
//------------------------------------------------------------------------------------------------

/*
Extending toolkit tabs component to add project specific styles
*/

.c-tabs__item:not(.is-active) .c-tabs__item-inner {
	color: #ffffff;
}

.c-tabs__item.is-active .c-tabs__item-inner {
	background-color: color('light-grey');
	border-bottom-color: color('light-grey');
}

.c-tabs__layout:before {
	display: none;
}

@each $bp-name, $bp-value in $mq-breakpoints {
	@include mq($until:#{$bp-name}) {
		.c-tabs--collapse\@max-#{$bp-name} {
			.c-tabs__item.is-active .c-tabs__item-inner {
				background: #ffffff;
			}
		}
	}
}

.c-tabs__item.is-active {
	&:focus .c-tabs__item-inner {
		border-top-color: $tab-border-color;
		background: linear-gradient(color('grey'), color('light-grey'));
	}
}