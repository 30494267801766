//------------------------------------------------------------------------------------------------
// LOGIN
//------------------------------------------------------------------------------------------------

.c-login {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: rem(30px) rem(20px);
	&.is-error-login .c-login__box {
		animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
		transform: translate3d(0, 0, 0);
		backface-visibility: hidden;
		perspective: rem(1000px);
	}
}

.c-login__box {
	width: 100%;
}

.c-login__layout {
	flex-grow: 0;
	box-shadow: 0 0 rem(10px) 0 rgba(0, 0, 0, 0.2);
	border-radius: $global-border-radius-large;
	overflow: hidden;
	flex-direction: column;
}

.c-login__logo {
	width: rem(200px);
	height: rem(49px);
}

.c-login__error {
	margin-top: spacing('small');
}

.c-login__forgot-link {
	opacity: 1;
	transition: opacity 0.25s;
}

.c-login.is-loading {
	.c-login__forgot-link {
		opacity: 0;
		pointer-events: none;
	}
}

.c-login__banner {
	height: 100%;
	object-fit: cover;
	object-position: 0 0;
}

@include mq(sm2) {
	.c-login__logo {
		width: rem(300px);
		height: rem(73px);
	}

	.c-login__error {
		margin-top: spacing('regular');
	}
}

@include mq(md) {
	.c-login {
		padding: rem(60px) rem(20px) rem(90px) rem(20px);
	}
}

@include mq(md3) {
	.c-login__layout {
		width:rem(903px);
		flex-direction: row;
	}
}
 
@include mq(lg) {
	.c-login {
		min-height: 100vh;
	}
}

@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%, 80% {
		transform: translate3d(2px, 0, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%, 60% {
		transform: translate3d(4px, 0, 0);
	}
}