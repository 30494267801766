//------------------------------------------------------------------------------------------------
// WIDTHS
//------------------------------------------------------------------------------------------------

/*
Utility classes to provide fluid widths to any element they're applied to. Most commonly used in
conjunction with the grid system object 'o-layout', but can be used with anything.
*/

/*
<div class="o-layout__item u-width-6-12">CONTENT</div>
*/


// DEFINE SETS
//------------------------------------------------------------------------------------------------

/*
The number of width utilties classes you wish to generate. By default,
a set of 12 of generated, but you can change this to something else if you
wish to use a grid system with a different number of columns.

For example: .u-width-1/2, .u-width-3/12, u-width-7/16 etc...
*/

$width-sets: 12 !default;


// WIDTHS MIXIN
//------------------------------------------------------------------------------------------------

@mixin create-widths($width-sets, $breakpoint-suffix: null) {

	/*
	[1] Reset margin-left to reset any offsets so we don't have to manually
	disable them with another class.
	*/

	@each $denominator in $width-sets {
		@for $numerator from 1 through $denominator {
			.u-width-#{$numerator}\/#{$denominator}#{$breakpoint-suffix} {
				width: ($numerator / $denominator) * 100% !important;
				max-width: ($numerator / $denominator) * 100% !important;
				flex-basis: ($numerator / $denominator) * 100% !important;
				margin-left: 0 !important; /* [1] */
				flex-grow: 1 !important;
			}
		}
	}

	.u-width-auto#{$breakpoint-suffix} {
		width: auto !important;
		flex-basis: 0 !important;
		margin-left: 0 !important; /* [1] */
		flex-grow: 1 !important;
	}

	.u-width-grow#{$breakpoint-suffix} {
		width: auto !important;
		flex-basis: auto !important;
		margin-left: 0 !important; /* [1] */
		flex-grow: 1 !important;
	}

	.u-width-shrink#{$breakpoint-suffix} {
		width: auto !important;
		flex-basis: auto !important;
		margin-left: 0 !important; /* [1] */
		flex-grow: 0 !important;
	}
}


// OFFSETS MIXIN
//------------------------------------------------------------------------------------------------

@mixin create-offsets($width-sets, $breakpoint-suffix: null) {
	@each $denominator in $width-sets {
		@for $numerator from 1 through $denominator {
			.u-offset-#{$numerator}\/#{$denominator}#{$breakpoint-suffix} {
				margin-left: ($numerator / $denominator) * 100% !important;
			}
		}
	}
}


// GENERATE STANDARD WIDTHS
//------------------------------------------------------------------------------------------------

// Example: .u-width-1/3
@include create-widths($width-sets);
// Example: .u-offset-1/3
@include create-offsets($width-sets);


// GENERATE RESPONSIVE WIDTHS
//------------------------------------------------------------------------------------------------

// Create responsive variants using settings.breakpoints
// Changes width when breakpoint is hit
// Example: .u-width-1/3@md

@each $bp-name, $bp-value in $mq-breakpoints {
	@include mq(#{$bp-name}) {
		@include create-widths($width-sets, \@#{$bp-name});
		@include create-offsets($width-sets, \@#{$bp-name});
	}
}


// MISC WIDTH CLASSES
//------------------------------------------------------------------------------------------------

// Breaks out of containers width to go full width of the viewport
.u-breakout {
	width: 100vw !important;
	position: relative !important;
	left: 50% !important;
	right: 50% !important;
	margin-left: -50vw !important;
	margin-right: -50vw !important;
}

// Breaks out of containers width until breakpoint is hit
// Example: u-breakout@max-md
@each $bp-name, $bp-value in $mq-breakpoints {
	@include mq($until: #{$bp-name}) {
		.u-breakout\@max-#{$bp-name} {
			width: 100vw !important;
			position: relative !important;
			left: 50% !important;
			right: 50% !important;
			margin-left: -50vw !important;
			margin-right: -50vw !important;
		}
	}
}
